import React, { useState } from "react";
import ReactPaginate from "react-paginate";

const termsAndConditionsText = [
  `<ul>
    <li>These General Terms and Conditions ("Terms") set out the rules and procedures that apply when You access and / or use the Platform, create and maintain an 
    Account with Us, purchase Our lottery tickets on the Platform, and/or play games. We recommend you to read our Privacy Policy also before accessing the Platform,
     creating an Account, purchasing lottery tickets on the Platform and/or entering games. The Terms and Conditions for Mobile Apps are available in the App itself.
     </li>
     <li> 
     By accessing and using the Platform, creating and maintaining an Account with Us, purchasing lottery tickets on Our Platform, and/or playing Games, You expressly
      agree to be bound by these Terms. Kindly read the rules mentioned in the lottery ticket.
   </li>
   <li> These Terms, Rules and Privacy Policy and Refund policy constitute the entire agreement between You and us in relation to You accessing Our Platform, creating an Account,
    purchasing lottery tickets and/or entering Games. By accepting these Terms, You agree to be legally bound by these Dear Government Lottery’s Terms, 
    Rules and Privacy Policy.<li>
     <h6>1. Definitions : </h6>
     <li>For the purposes of this Terms & Conditions:<li>
     </ul>
     <ul>
         <li><i class="fa fa-circle"></i> “Account” means a unique account created for You to access our Platform or parts of our Platform.</li>
         <li><i class="fa fa-circle"></i> “Application” refers to Dear Government Lottery App, the software program provided by the Company.</li>
         <li><i class="fa fa-circle"></i> “Company” (referred to as either "the Company", "We", "Us" or "Our" in this Policy) refers to Charles Technologies Private Limited.</li>
         <li><i class="fa fa-circle"></i> “Cookies” are small files that are placed on Your computer, mobile device or any other device by our website or mobile application, containing the details of Your browsing history.</li>
     
     
  <li>
    <strong>Device:</strong> Any device that can access the Platform or mobile application such as a computer, mobile, any device which can be used to play our games.
  <li>
  <li>
    <strong>Personal Data:</strong> Any information that relates to an identified or identifiable individual.
  <li>
  <li>
    <strong>Platform:</strong> Refers to the Website or Mobile Application or both.
  <li>
  <li>
    <strong>Service Provider:</strong> Any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
  <li>
  <li>
    <strong>Usage Data:</strong> Data collected automatically, either generated by the use of the Platform or from the Platform infrastructure itself (for example, the duration of a page visit).
  <li>
  <li>
    <strong>You or User(s):</strong> Means the individual accessing or using the Platform, or the company, or other legal entity on behalf of which such individual is accessing or using the Platform, as applicable.
  <li>
</ul>
  <h6>2.Account Registration:</h6>
  <ul>
  <li>
    <strong>a) Eligibility:</strong> To play Dear Government Lottery You must be at least 18 years or older &amp; abide by these Terms and the Rules;
    It is mandatory that you shall be physically present in the territories where online lottery is legally permitted in India by the respective state governments.
  <li>
  <li>
    <strong>b) General:</strong> For the purposes of this Terms &amp; Conditions:
  <li>
  <li>
    To open your account, we will need some details about you. Please ensure every bit is honest, accurate, and always kept it up-to-date. Updating your information is essential to enable us process your tickets or winnings and payouts. Incorrect or incomplete details in your account can result in difficulties in processing of winnings or refunds or any settlements. Hence, You are required to update your account with real and complete details to facilitate effective services from our end.<li>
    <li>We may occasionally request additional information to verify what you have provided. We shall take all reasonable steps necessary to keep your account secure and comply with any applicable legal requirements. All information you share with us falls under the protection of our Privacy Policy, which you agree to by sharing your data. In essence, you trust us to hold and process your information responsibly, guided by the principles outlined in that policy. If you have any query or objection to sharing information, please reach out to our customer service for clarifications.<li>
  
  <li>
    <strong>c) How to Register? - Account Creation:</strong>
  <li>
  </ul>
  <ul>
    <li><i class="fa fa-circle"></i> One user can register one account only. A single user is not permitted to operate multiple accounts with one bank account.</li>
    <li><i class="fa fa-circle"></i> New users need to click on the “Sign Up" button and provide necessary information like name, email, phone number, date of birth and create a password or login through Gmail, Apple ID or Facebook.</li>
    <li><i class="fa fa-circle"></i>  Immediately thereafter, Users receive a verification code via email or SMS and they enter the code to verify their account.</li>
    <li><i class="fa fa-circle"></i> After verification, users create their lottery account with a unique username (Users are advised to use username which are not vulgar, obscene, of any affiliation to political parties, religion or caste or national leaders).</li>
    <li><i class="fa fa-circle"></i> Subsequently, Users land on the application's homepage where they can view the list of available lotteries.</li>
    <li><i class="fa fa-circle"></i> Users can then navigate through the application to discover the available lotteries.</li>
    <li><i class="fa fa-circle"></i> Each lottery will have unique features, such as the number of tickets, ticket prices, and the prize list.</li>
    <li><i class="fa fa-circle"></i> Users can select the lottery they want to participate and review the details provided by the administrator, including the total number of tickets available, ticket prices, and the winnings %.</li>
  </ul>

  <h6>3.KYC:</h6>
  <ul>
    <li><i class="fa fa-circle"></i> The User needs to click on "Verify PAN Card, Aadhar Card, Namematch, selfie verification and Bank Account Verification” tab on the application.</li>
    <li><i class="fa fa-circle"></i> The User needs to enter PAN/Aadhar card details accurately.</li>
    <li><i class="fa fa-circle"></i> The User needs to upload a clear picture of their PAN card/Aadhar card and a selfie, ensuring all information is visible.</li>
    <li><i class="fa fa-circle"></i> Then the User shall enter their bank account number, IFSC code, branch name, bank name and submit their KYC request.</li>
    <li> <i class="fa fa-circle"></i> If all the documents are clear, we will process their verification within 1 business day.</li>
    <li> <i class="fa fa-circle"></i> We shall seek any additional document(s) for verification purposes.</li>
      <li> <i class="fa fa-circle"></i> The User can check the verification status under the "Verification" or "KYC"
section in their wallet. The verification will be notified via registered email of
the user.</li>
<li><i class="fa fa-circle"></i> Only bank accounts linked to the User’s PAN card can be verified.</li>
<li><i class="fa fa-circle"></i> We cannot verify NRE (Non-Resident External) bank accounts or wallets.</li>
<li><i class="fa fa-circle"></i> The User cannot use the same PAN card/Aadhar Card once verified on
another account.</li>
  </ul>`,
  `<h6>4. How to purchase our tickets :</h6>
<ul>
<li><i class="fa fa-circle"></i> Users pick their lucky numbers or use the application's random number
generator to make their selection.</li>
<li><i class="fa fa-circle"></i> User can choose the selected numbers and confirm their ticket purchase. They
need to ensure that they review their choices before confirming the bid, as
changes are not be possible after submission.</li>
<li><i class="fa fa-circle"></i> The tickets chosen by the users will be available in their account for their
reference. The ticket details will also be sent by email to their registered email
account.</li>
</ul>
<h6>Draw and Timings:</h6>

<ul>
<li>Winning Range:</li>
<li><i class="fa fa-circle"></i>  The application will set a winning range within the total available numbers.
We use a computerized system which guarantees that the winning numbers
are selected randomly. This computerized mechanism is regularly tested and
a time-stamping mechanism is in place to make sure the results are
protected and there is no human involvement.</li>
<li><i class="fa fa-circle"></i> The timing of the draw will be mentioned in the lottery game itself. Users
should note the draw timings and the Winners will be announced at this
scheduled time.</li>
<li><i class="fa fa-circle"></i> On the day and time specified, the draw will be conducted and the results will
be notified to the players by email or sms (as subscribed by the users).</li>
<li><i class="fa fa-circle"></i> If the User’s selected numbers fall within this winning range during the draw,
they win the corresponding prize.</li>
</ul>
<h6>5. Claiming Prizes: </h6>
<ul>
<li><i class="fa fa-circle"></i> Winners will be notified through their registered email, mobile numbers or
website or application. </li>

<li><i class="fa fa-circle"></i> Prizes will be credited directly to User’s wallet after deducting TDS.
</li>

<li><i class="fa fa-circle"></i> Winners can withdraw the amount through their wallet in their account.</li>
</ul>
<h6>6. Wallet: </h6>

<ul>
<li><i class="fa fa-circle"></i>Adding Money/ Funds: The User needs to click "Add" tab in the wallet
section.</li>

<li><i class="fa fa-circle"></i>
 The User needs to choose the amount of money they wish to add through
options provided therein.
</li>

<li><i class="fa fa-circle"></i> Payment Method: Then the User shall select from available options
(Credit/Debit cards, UPI, Net Banking, Wallets).
</li>

<li><i class="fa fa-circle"></i>Complete Payment: Follow their payment provider's instructions to finalize
the transaction.
</li>

<li><i class="fa fa-circle"></i>Winning and transferring the amount in the Winner’s Account: If the
User wins, the prize money is credited to their "Wallet."
</li>
</ul>
<h6>7. Withdrawal of winning amount: </h6>
<ul>

<li>a) Within the Wallet, the User has to choose the option to withdraw
from the money credited towards the winnings.</li>
<li>b) The User has to enter the amount he/she wish to withdraw.</li>
<li>
c) If not already linked, the User needs to provide their bank account details
where they want to receive the money.</li>
<li>
d) We process the withdrawal request, usually within a 3 days.</li>
<li>
e) Payment and money transfers are subject to RBI guidelines and any bank
clearances. Users may contact our customer service in case of any issues. We
endeavor to respond to the queries in 1-2 business days.</li>

<h6>8. Account Access & Responsibility : </h6>
<li>For the purposes of this Terms & Conditions:</li>
<li><i class="fa fa-circle"></i>
Accessing Games & Prizes: Upon opening your account, avail yourself of a
complimentary entry with each purchase, subject to availability.</li>
<li>
<i class="fa fa-circle"></i>Personal Responsibility: Maintain the confidentiality and security of your
password and account details. Take all necessary precautions to prevent
unauthorized access. We bear no liability for damages or losses resulting
from your negligence or other lapses in security at your end.</li>
<li>
<i class="fa fa-circle"></i>Unauthorized Access: While we commit to investigating suspicious
activities, any losses incurred due to the unintended disclosure of your
password remain the responsibility of the account holder.</li>
<li>
<i class="fa fa-circle"></i>Password Recovery: Forgot your password? No cause for concern. Simply
follow the platform's prescribed reset instructions for a seamless recovery
process.</li>
<li>
<i class="fa fa-circle"></i> Player Recognition: The sole individual officially registered as the Account
holder shall be recognized as the "Player." Prizes, in accordance with the
Rules, shall be disbursed exclusively to the registered account holder.</li>
<li>
<i class="fa fa-circle"></i> Exclusion of certain individuals: Minors, persons of unsound mind,
suffering from any incapacities, gaming addicts etc. In case if we come to
know later during or post the draw or notified by any third party, then we
reserve our right to remove such Account from our platform without any
notice to them. Any amount in their wallet will be refunded based on legal
guardian’s approval or through any order from the court of competent
jurisdiction.</li>
<h6>
9. Termination, Variation, or Suspension of Your Account: </h6>
<li>
We reserve the right, at our absolute discretion, to terminate, vary, or suspend
access to Your Account, the Platform, limit the rights to purchase tickets, purchase of
lottery ticket, entry into a Draw, or Your ability to make a withdrawal or transfer
from Your Winnings or balance. This may occur immediately and without prior
notification if:</li>
<li>
<i class="fa fa-circle"></i> You register or operate more than one (1) Account simultaneously (in such
cases, we reserve the right to terminate, vary, or suspend some or all of those
Accounts).</li>
<li>
<i class="fa fa-circle"></i>Any information provided to Us is found to be fraudulent, false, inaccurate,
incomplete, or outdated.</li>
<li>
<i class="fa fa-circle"></i> You participate in a Draw while located in a jurisdiction where such entry is
not permitted.</li>
<li>
<i class="fa fa-circle"></i>Essential repair, maintenance, or upgrades on the Platform are required.</li>
<li>
<i class="fa fa-circle"></i>Based on the request of law enforcement agency of the competent jurisdiction
or an order from the court of competent jurisdiction.</li>
<li>
<i class="fa fa-circle"></i>We cease to provide the application or other functionalities such as Accounts
or access to the Platform for any reason.</li>
<li>
<h6>10. Platform Access and Use : </h6>
<li>Access to the Platform is provided free of charge, and you are responsible for
ensuring your device compatibility. The Platform is offered "as is" and "as available,"
and we disclaim all warranties. We reserve the right to alter, suspend, or discontinue
the Platform at any time without notice.</li>
<li>
You must comply with local laws, regulations, and ensure lawful use of your Account
and the Platform. We are not liable for entries made from other states in the country
or foreign jurisdictions where lottery is not permitted. You agree to indemnify us for
any wrongful use. You are advised to check your legal right to use our services to
avoid any jurisdictional issues. The content on the Platform is protected by
intellectual property laws, and you may not reproduce or reuse it without our
written permission. Unauthorized reverse engineering or decompiling of associated
software is strictly prohibited.</li>
   </ul>`,
  `<h6>11. Responsible Gaming / Online Lottery draw Practices :</h6>
<li>We are committed to responsible gaming. We encourage our members to play
responsibly and set limits on their spending. If you have any problems with
overspending, please contact our customer support team for help.
Our commitment to responsible online lottery draws is evident in the stringent
measures we have implemented to ensure the protection of players. We strictly
adhere to age restrictions diligently, displaying clear signage on our website,
incorporating age controls during registration. We strictly prohibit participation for
individuals below the legal age for online lottery draws. Our advertising practices
avoid targeting minors, and we provide resources such as links to filtering programs
to prevent underage access.</li>
<ul>
<li>We are in compliance with all applicable laws including the following regulations:</li>
<li>i) The Digital Personal Data Protection Act, 2023; and
</li>
<li>
ii) Guidelines for Prevention of Misleading Advertisements and Endorsements for
Misleading Advertisements, 2022.</li>
<li>
iii) The Lotteries (Regulation) Act, 1998 and the Lottery (Regulation) Rules, 2010 made thereunder.</li>
<li>
Moreover, we prioritize the well-being of our players by offering tools for
self-control. Players can set spending limits voluntarily, take mandatory breaks
during play, and utilize features for self-exclusion.
Our platform promotes transparency in players' online behaviors, with visible
betting histories and accessible information on probabilities, payout ratios, and
game structures. Our dedicated staff undergo responsible online lottery draw
training, ensuring they are equipped to assist players responsibly. We continuously
evaluate and audit our strategies to maintain a secure and socially responsible
online lottery draw environment. Users are encouraged to report incidents of other
users suspected to be addicted or, who spends unreasonable amount of time in
playing.
</li>
</ul>
<h6>12. Links to Other Websites :</h6>
<li>Links to external third-party sites may be provided on the Online Services and other
communications sent by us. Unless expressly stated, these sites are beyond our
control. We neither assume nor accept any responsibility or liability for the content or
issues, if any, arising therefrom from such third-party sites. The inclusion of a link to
another site on the Online Services or other communication is for informational
purposes only and does not imply any endorsement of the sites themselves or of
those in control of them.
The application is certified as per IS/ISO/IEC 27001 on "Information Technology –
Security Techniques - Information Security Management System." You are informed
to download only from our authorized website <a href="www.dearlotteries.app"  class="hyperReference"> www.dearlotteries.app</a> or from
Google Playstore or IOS App store only. If you are downloading from any other internet
sources, it may be corrupted or endangered with files which can harm your device
or defraud your money or account.</li>
<h6>13. Disclaimer of Warranty and Liability :</h6>
<li>The information on the Platform is provided for general information purposes only,
and we make no warranty, representation, or guarantee regarding its completeness,
accuracy, or security. Your use of the Platform is at your sole risk, and we do not
guarantee uninterrupted or error-free access.
We direct our content only to eligible Users and disclaim responsibility for any
interruptions, access delays, data issues, losses, or events beyond our control. By
using the Platform, you agree to these terms and acknowledge that neither we nor
our affiliates are liable for any such issues or inaccuracies in the content.</li>
<h6>14. Limitation of Liability :</h6>
<li>We shall not be liable for any loss or damage, foreseeable or otherwise, resulting
from your use of the Platform. This includes issues related to account registration,
lottery ticket purchase attempts, game entries, or any platform failures, even if you
have previously alerted us to potential losses.
We also disclaim liability for losses arising from events beyond our control, system
failures, mistakes, delays, refusals, or losses caused by your actions or technical
issues. To the fullest extent permitted by law, we exclude all representations,
warranties, and guarantees.</li>
<h6>15. Additional Terms and Conditions :</h6>
<ul>
<li>
<i class="fa fa-circle"></i> We reserve the right to cancel or suspend any lottery Draw at any time.</li>
<li>
<i class="fa fa-circle"></i>We reserve the right to disqualify any player from playing the Draw or
claiming their winnings if they violate these terms and conditions or if they
engage in any fraudulent or illegal activity.</li>
<li>
<i class="fa fa-circle"></i>
We are committed to protect the privacy of our players. Please review our
Privacy Policy for more information about how we collect, use, and share your
personal information.
</li>
<li>
<i class="fa fa-circle"></i> We reserve the right to change these terms and conditions at any time. If we
make any changes, we will post the updated terms and conditions on our
website/ Application.
</li>
</ul>
<h6>16. CHEATING AND SOFTWARE BUGS :</h6>
<ul>
<li>16.1 We reserve the right to review transaction records and logs and upon such a
review should we at our sole discretion deem a player to be participating in
promotional abuse either on their own or as part of a group, we reserve the
right to a) Revoke, deny or withdraw an offer from the Player, and/or b)
Exclude Player from future promotional offers, and/or c). Terminate the
Account with immediate effect.</li>
<li>
16.2 Any form of fraudulent activity on the application, as determined at our sole
discretion, is strictly prohibited.</li>
<li>
16.3 Fraudulent activity may include, but is not limited to, stolen credit cards or
digital identities, forgery, using any technical or manipulative means to control
the software for desired results, collusion, and the provision of false
Registration Data or other requested information.</li>
<li>
16.4 In addition to any other remedies provided under these Terms and Conditions,
we may pursue claims for criminal prosecution and/or civil damages for any
fraudulent activities.</li>
<li>
16.5 We are committed to fair play and to combating fraudulent activity and has
zero tolerance towards inappropriate play and fraudulent activity. Any Player
involved in any form of suspected fraudulent activity will be reported to the
appropriate authorities.</li>
<li>
16.6 Software Bugs: Player agrees not to attempt to exploit any error, virus, bug,
defect or inconsistency ("Bug") found in the Software, to their advantage at
any time. Furthermore, Player agrees to report any such Bug to customer
service immediately upon discovery of same.</li>
<li>
16.7 If the Services contains a bug or misconfiguration that causes incorrect
behaviour or payout, we have the right to remove the Draw/game
and void any related draw and winnings.</li>
<li>
16.8 Any form of cheating on the application, as determined at our sole discretion,
is strictly prohibited.</li>
<li>
16.9 Cheating includes any form of manipulation of the outcome of the hand or
game so that the odds of winning are changed to favour the Player and against any other players from winning. In
particular, and without limitation, cheating includes any form of 'collusion' as
that term is commonly understood in the industry.</li>
<li>
16.10 In addition to any other remedies provided under these Terms and Conditions,
we may pursue claims for criminal prosecution and/or civil damages against
any form of cheating committed in the use of our Platform/ services.</li>
<li>
</ul>
<h6>17. LIMITED LICENSE TO, AND INTERFERENCE WITH THE APPLICATION : </h6>
<ul>
<li>17.1 We hereby grant you a limited, non-exclusive, non-transferable personal license
to access and use the application and the materials and Services contained
therein. We provide the materials and Services available on this application for
the personal, non-commercial use by Players or visitors of the application. Any
unauthorized use of the application or any of the Materials or Services
contained therein terminates this limited license effective immediately. This is a
license to use and access the application for its intended purpose only.</li>
<li>
<li>17.2 You may not translate, reverse-engineer, decompile, disassemble, or make
derivative works from our application's materials, graphics, text, coding,
images or Software. The Player hereby agrees not to use any automatic device
or manual process to monitor or reproduce the application, and will not use
any device, software, computer code, or virus to interfere or attempt to disrupt
or damage the application or any communications on it.</li>
</ul>
<h6>18. RESTRICTIONS ON USE OF APPLICATION :</h6>
<ul>
<li>18.1 You may use the application only for purposes expressly permitted by the Terms
and Conditions of the application.</li>
<li>
18.2 Only one (1) account is allowed per person. No person will be allowed to
maintain more than one account. If a player opens more than one account
on the application, we reserves the right to:</li>
<li>
a) Block play for any account(s) where it believes such activity has occurred;</li>
<li>
b) Withhold account balances on the blocked account(s) indefinitely and to
confiscate any and all funds; and/or</li>
<li>
c) Close any other accounts that are traced to the same address;</li>
<li>
18.3 Player is not allowed to fund accounts on behalf of other players.</li>
<li>
18.4 Without the express prior written authorization from us, you may not:</li>
<li>
a) Duplicate the application or any of the materials contained therein;</li>
<li>
b) Create derivative works based on the application or any of the
Materials contained therein;</li>
<li>
c) Use the application or any of the materials contained therein for any
public display, public performance, sale or rental;</li>
<li>
d) Re-distribute the application or any of the materials contained therein;</li>
<li>
e) Remove any copyright or other proprietary notices from the
application or any of the materials contained
therein;</li>
<li>
f) Frame or utilize any framing techniques in connection with the
application or any of the materials contained therein;</li>
<li>
g) Use, publish or distribute any meta-tags or any other "hidden text"
using the application’s name or marks;</li>
<li>
h) Circumvent any encryption or other security tools used anywhere on
the application (including the theft of nicknames/usernames and
passwords or using another person's nickname/username and
password in order to gain access to a restricted area of the application);</li>
<li>
i) Use any data mining, bots, or similar data gathering and extraction
tools on the application;</li>
<li>
j) Use any device, software or routine to bypass any operational element
or to interfere, or attempt to interfere, with the proper working of the
application, server or activities conducted therein;</li>
<li>
k) Take any action that imposes an unreasonable or disproportionately
large load on the application or its network infrastructure;</li>
<li>
l) Decompile, reverse engineer, modify or disassemble any of the software
aspect of the materials except and only to the extent permitted by
applicable law;</li>
<li>
m) Sell, rent, lease, license, sublicense, transfer, distribute, re-transmit,
time-share, use as a service bureau or otherwise assign to any third
party the materials or any of your rights to access and use the
materials;</li>
<li>
n) Transmit, distribute, post or submit any information concerning any
other person, member or entity, including without limitation, personal
contact information or credit, debit, passwords, or account numbers;</li>
<li>
o) Use the application in connection with the distribution of unsolicited
commercial email or advertisements;</li>
<li>
p) Access the application from any location where it is not permissible to
access online lottery or gaming services.</li>
<li>
q) Assist or aid any third party in doing any of the foregoing</li>
</ul>.`,
  `<h6>19. SOFTWARE LICENCE: </h6>
<ul>
<li>19.1 Use of the application encompasses the use of certain computer software,
coding and information developed by the application ("Software"). The
application grants to Player a non-exclusive, temporary, revocable license,
without the right to sub-license, to use the Software.</li>
<li>19.2 You may not: 
</li>
<li>(a) permit other individual(s) to use the Software unless such
other individual(s) agree in writing to accept the terms of this Agreement; (b)
modify, translate, reverse engineer, de-compile, disassemble or create derivative
works based upon the Software; (c) copy the Software; (d) rent, lease, transfer,
sublicense or otherwise transfer rights to the Software; (e) remove any
proprietary notices or labels on the Software, including the code underlying the
Software; or (f) use the Software for any reason other than your private use.
Ownership of the Software, and all rights existing therein, including all forms of
copyright, trademark, patent, trade secret, shall remain our sole and exclusive
property. Player's right to make use of the Software is conditioned on
compliance with all of the Terms and Conditions set forth herein.</li>
<li>
</ul>
<h6>
20. INDEMNITY :</h6>
<ul>
<li>
You agree to defend, indemnify, and hold us harmless, our officers, directors,
shareholders, employees, independent contractors, service providers, and
agents, from and against any and all claims, actions, loss, liabilities, expenses,
costs, or demands, including without limitation legal and accounting fees, for
all damages directly, indirectly, and/or consequentially resulting or allegedly
resulting from your, or you under another person's authority including
without limitation to governmental agencies, use, misuse, or inability to use the
application or any of the materials or services contained therein, or your
breach of any of these Terms of Service, or should you violate any law. You do
also agree to defend and indemnify us should any third party be harmed by
your illegal actions or should we be obligated to defend any claims
including, without limitation, any criminal action brought by any party not
affiliated with this application. We shall promptly notify you of any such claim
or suit, and cooperate fully (at your expense) in the defense of such claim or
suit. We reserve the right to participate in the defense of such claim, but are not
obligated to do so.
</li>
</ul>
<h6>21. PLAYER REPRESENTATIONS AND WARRANTIES :</h6>
<ul>
<li>You hereby represent and warrant to us as follows:</li>
<li>
21.1 You have read and you understand these Terms and Conditions and the
Privacy Policy and the Refund Policy.</li>
<li>
21.2 The execution, delivery and performance by you of these Terms of Service
and the consummation by you of the transactions contemplated hereby will
not, with or without the giving of notice, the lapse of time or both, conflict
with or violate: (i) any provision of law, rule or regulation to which you are
subject; (ii) any order, judgment or decree applicable to you or binding upon
your assets or properties; or (iii) any agreement or other instrument
applicable to you or binding upon your assets or properties.</li>
<li>
<ul>
<h6>22. COPYRIGHT INFORMATION: </h6>
<ul>
<li>22.1 The materials, such as text, graphics, photographs, video and audio clips,
music, soundtracks, icons, streaming data, animation, images, downloadable
materials, data compilations and software, accessible from the application, and
any other World Wide Web site owned, operated, licensed, or controlled by the
application, is the proprietary information and valuable intellectual property of
us or the party that provided the materials to us, and we or the party that
provided the materials to us retains all right, title, and interest in the materials.</li>
<li>
22.2 The materials may not be copied, distributed, republished, modified,
uploaded, posted, or transmitted in any way without our prior written consent,
except that you may print out a copy of the materials solely for your personal use.
In doing so, you may not remove or alter, any copyright, trademark, trade name,
service mark, or any other proprietary notice appearing on any of the materials.</li>
</ul>
<h6>
23. GOVERNING LAW, JURISIDICTION AND DISPUTE RESOLUTION :</h6>
<ul>
<li>
23.1 These Terms and Conditions (including the policies) and the relationship
between you and us shall be governed by and construed in accordance with the
laws of India, without giving effect to conflict of law principles.</li>
<li>
23.2 In case of any disputes, you agree to submit to the exclusive jurisdiction of
courts at Chennai, Tamil Nadu, India.</li>
<li>
</ul>
<h6>24. FORCE MAJEURE :</h6>
<ul>
<li>
We shall not be responsible for any failure to perform due to unforeseen
circumstances or to causes beyond our reasonable control, including but not
limited to: acts of God, such as fire, flood, earthquakes, hurricanes, tropical storms
or other natural disasters; war, riot, arson, embargoes, acts of civil or military
authority, or terrorism; fiber cuts; strikes, or shortages in transportation,
facilities, fuel, energy, labor or materials; failure of the telecommunications or
information services infrastructure; hacking, or any failure of a computers,
internet, server or software, for so long as such event continues to delay the
application's performance.
</li>
</ul>
<h6>
25. GENERAL PROVISIONS :</h6>
<ul>
<li> 
25.1 Severability. If for any reason a court of competent jurisdiction finds any
provision of these Terms and Conditions, or any portion thereof, to be
unenforceable, that provision will be enforced to the maximum extent
permissible and the remainder of these Terms and Conditions will continue in
full force and effect.</li>
<li>
25.2 No Waiver. No waiver from our end shall be deemed a waiver of any
subsequent default of the same provision of these Terms and Conditions.</li>
<li>
25.3 Headings. All headings are solely for the convenience of reference and shall not
affect the meaning, construction or effect of these Terms and Conditions.</li>
<li>
25.4 Modifications. We reserve the right to change any of the provisions posted
herein at any time. Kindly view our updates to the policy from time to time.</li>
</ul>
<h6>
26. PRIVACY POLICY :</h6>
<ul>
      <li>
        In order to provide You with access to our services, we collect and process some
        of your personal information. Please refer to our Privacy Policy at
        <a href="https://dearlotteries.app/PrivacyPolicy" class="hyperReference" target="_blank" rel="noopener noreferrer">
          https://dearlotteries.app/PrivacyPolicy
        </a> which contains details on the types
        of information collected, and how this information is used.
        
      </li>
      <li>
      The Platform is owned and operated by Charles Technologies Private Limited.
        Customer Service Centre can be contacted on <a href="tel:8489925481" class="hyperReference"> +91 8489925481 </a>or by sending an
        email to <a href="mailto:support@dearlotteries.app" class="hyperReference">support@dearlotteries.app</a>
      </li>
      <li>
      Grievance Officer Mr. Shree Vishnu Thangamuthu can be reached at email:<a href="mailto:support@dearlotteries.app"  class="hyperReference"> support@dearlotteries.app </a>.
      </li>

    </ul>`,
];

const itemsPerPage = 1;

function TermsCondition() {
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = termsAndConditionsText.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(termsAndConditionsText.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % termsAndConditionsText.length;
    setItemOffset(newOffset);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="" style={{ backgroundColor: "#fff" }}>
      <style>
        {`
      li {
        color: #000;
      }
      .pagination li.active a {
background-color: #000;   
color: #fff !important;
border-color: #fff;
}
.pagination li a {
 color: #000 !important;
background-color: #fff;
    }
    .hyperReference {
      color: #253974 !important;
      text-decoration: underline;
      font-weight: 600;
      font-size: 11px;
    }
    .pagination li a:hover{
      background-color: #000;   
color: #fff !important;
border-color: #fff;
    }
    `}
      </style>
      <section
        className="section-padding contact px-3"
        style={{ textAlign: "justify" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-heading center-heading text-center mb-60">
                <h3 className="heading-title">TERMS AND CONDITIONS</h3>
                <p>Date of Issue: 19th JUNE, 2024</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 col-sm-12 col-md-12">
              {currentItems &&
                currentItems.map((text, index) => (
                  <div key={index} dangerouslySetInnerHTML={{ __html: text }} />
                ))}
            </div>
          </div>
          <div className="row justify-content-center pb-20">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel="..."
              pageCount={pageCount}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default TermsCondition;